html {
    height: 100%;
    background-image: url("../img/bg.webp");
    background-size: cover;
}

.follow-me {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-decoration: none;
    color: #ffffff;
}
